// Dreamland - Elan
//
$md-dutchdelux-elan: (50 : #fcf2e7,
100 : #f8dec3,
200 : #f3c89c,
300 : #eeb274,
400 : #eaa256,
500 : #e69138,
600 : #e38932,
700 : #df7e2b,
800 : #db7424,
900 : #2EC4B6,
A100 : #ffffff,
A200 : #ffe3d4,
A400 : #ffc3a1,
A700 : #ffb287,
contrast: (50 : #2EC4B6,
100 : #ffffff,
200 : #ffffff,
300 : #ffffff,
400 : #ffffff,
500 : #ffffff,
600 : #ffffff,
700 : #ffffff,
800 : #ffffff,
900 : #ffffff,
A100 : #ffffff,
A200 : #ffffff,
A400 : #ffffff,
A700 : #ffffff,
));

$dutchdelux-primary: mat-palette($md-dutchdelux-elan, 900);
$dutchdelux-accent: mat-palette($md-dutchdelux-elan, 200, 500, 300);
$dutchdelux-warn: mat-palette($md-dutchdelux-elan, 800);

$dutchdelux-theme: mat-light-theme($dutchdelux-primary, $dutchdelux-accent, $dutchdelux-warn);


:root.dutchdelux {
  @include angular-material-theme($dutchdelux-theme);
  --bg-color: #011627;
  --color: #FDFFFC;
  --header-color: #FF9F1C;
  color: #FDFFFC !important;

  .mat-form-field-label,
  .mat-tab-label,
  .mat-tab-link {
    color: white !important;
  }
}
