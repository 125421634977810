@import '~@angular/material/theming';
@include mat-core();

// Dark theme
//
$dark-app-primary: mat-palette($mat-green);
$dark-app-accent: mat-palette($mat-light-blue, A200, A100, A400);
$dark-app-warn: mat-palette($mat-red);
$dark-app-theme: mat-dark-theme($dark-app-primary, $dark-app-accent, $dark-app-warn);

:root.dark {
  @include angular-material-theme($dark-app-theme);
  --bg-color: black;
  --color: white;
  --header-color: #1c1c1e;
  --border-color: white;
  --highlight-color: #4caf50;

  eva-config-picker {
    --border-color: black;
  }
}

// Light theme
$light-primary: mat-palette($mat-grey, 900, 500, 300);
$light-accent: mat-palette($mat-brown, 200);
$light-warn: mat-palette($mat-deep-orange, 200);
$light-theme: mat-light-theme($light-primary, $light-accent, $light-warn);

// Winter theme
$winter-primary: mat-palette($mat-blue, 50, 100, 200);
$winter-accent: mat-palette($mat-blue, 200, 300);
$winter-warn: mat-palette($mat-deep-orange, 200);
$winter-theme: mat-dark-theme($winter-primary, $winter-accent, $winter-warn);


// Hacker vibe
$hacker-primary: mat-palette($mat-light-green, A200);
$hacker-accent: mat-palette($mat-light-green, A400);
$hacker-warn: mat-palette($mat-deep-orange, A700);
$hacker-theme: mat-dark-theme($hacker-primary, $hacker-accent, $hacker-warn);



:root.light {
  @include angular-material-theme($light-theme);
  --bg-color: #FAFAFA;
  --color: black;
  --header-color: white;
  --logo-color: #c2c2c2;
  --border-color: #C2C2C2;
  --highlight-color: #03a9f4;
}

:root.winterIsHere {
  @include angular-material-theme($winter-theme);
  --bg-color: #00171F;
  --color: #ffffff;
  --header-color: #007EA7;
  --logo-color: #00A8E8;
}

:root.coralHeaven {
  @include angular-material-theme($winter-theme);
  --bg-color: #564256;
  --color: #ffffff;
  --header-color: #FC814A;
  --logo-color: #E8E8E8;
}

:root.hackerVibe {
  @include angular-material-theme($hacker-theme);
  --bg-color: #000000;
  --color: #c6ff00;
  --header-color: #aeea00;
  --logo-color: white;
}

:root.unicornMode {
   @include angular-material-theme($light-theme);
  --bg-color: pink;
  --color: black;
  --header-color: #f593a4;
  --logo-color: white;
  --border-color: black;
  --highlight-color: #e83e8c;
  *:not(mat-icon) {
    font-family: "Comic Sans MS"
  }
}

// Custom themes
//
@import './themes/dutch-delux.scss';
