:root {
  --header-height: 56px;
}

/* You can add global styles to this file, and also import other style files */
body {
  background-color: var(--bg-color);
  margin: 0;
  color: var(--color);
}

button {
  &:focus {
    outline: none;
  }
}

router-outlet {
  display: block;
  margin-top: var(--header-height);
}

*::-webkit-scrollbar {
  display: none;
}


img[eva-image] {
  width: 100%;
  height: 100%;
  object-fit: inherit;
  transition-property: cubic-bezier(0.4, 0.0, 1, 1);
  transition: 300ms;
  opacity: 0;
  &.loaded {
    opacity: 1;
    transition-property: cubic-bezier(0.0, 0.0, 0.2, 1);
  }
}

eva-config-picker {
  --config-picker-background-color: var(--bg-color);
  --config-picker-card-background-color: var(--bg-color);
}

// This is for the ui editor component
eva-ui-editor {

  .form-container {
    display: flex;
    mat-form-field {
      margin-left: 8px;
    }
  }

  h6 {
    margin: 0;
    padding: 12px 8px;
  }

  .line {
    color: rgba(0,0,0,0.87);
    font-size: 14px;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.12) 50%, transparent 0%);
    background-repeat: repeat-y;
    background-size: 2px 6px;
    min-width: 2px;
    width: 2px;
    margin-bottom: 20px;
  }

  .plus-dots {
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0.12) 50%, transparent 0%);
    background-position: center;
    background-repeat: repeat-x;
    background-size: 6px 2px;
    height: 46px;
    width: 16px;
  }

  .main-container {
    display: flex;
    flex-direction: row;
  }


}

eva-config-picker-endpoint-status {
  --error-color: red;
  --success-color: green;
}

mat-icon.warning {
  color: orange;
}
